export default class AppRoutes
{
    static app = "/app";
    static signUp = AppRoutes.route("signup");
    static selectPackage = AppRoutes.route("select-package");
    static remindMe = AppRoutes.route("remind-me");
    static thankYou = AppRoutes.route("thank-you");

    static route(endpoint: string): string
    {
        return `${AppRoutes.app}/${endpoint}`;
    }
}
