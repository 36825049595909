import React from 'react';

interface NotFoundProps {
}

const NotFound: React.FC<NotFoundProps> = () =>
{
    return (
        <div>404: Not Found</div>
    )
};

export default NotFound;
