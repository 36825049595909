import 'app/styles/antd.less';
import 'app/styles/style.scss';
import React, {Suspense, useEffect} from "react";
import {observer} from "mobx-react";
import {Route, Routes, BrowserRouter, Navigate} from 'react-router-dom'
import AppRoutes from "app/constants/AppRoutes";
import SignUp from "sign-up/components/SignUp";
import Home from "home/components/Home";
import RemindMe from "remind-me/components/RemindMe";
import SelectPackage from "select-package/components/SelectPackage";
import NotFound from "app/components/not-found/NotFound";
import ThankYou from "thank-you/components/ThankYou";
import {appStore} from "index";

interface AppProps {
}

const App: React.FC<AppProps> = observer(() =>
{
    useEffect(() =>
    {
        // noinspection JSIgnoredPromiseFromCall
        onLoad();
    }, []);

    async function onLoad()
    {
        await appStore.initialize();
    }

    if(!appStore.isInitialized) return null;

    return (
        <Suspense fallback="">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Navigate to={AppRoutes.app} />} />
                    <Route path={AppRoutes.app} element={<Home />} />
                    <Route path={AppRoutes.signUp} element={<SignUp />} />
                    <Route path={AppRoutes.remindMe} element={<RemindMe />} />
                    <Route path={AppRoutes.selectPackage} element={<SelectPackage />} />
                    <Route path={AppRoutes.thankYou} element={<ThankYou />} />

                    <Route element={NotFound} />
                </Routes>
            </BrowserRouter>
        </Suspense>
    )
});

export default App;
