import {makeAutoObservable} from "mobx";
import {isNullOrEmpty} from "app/utils/string-utils";
import {message} from "antd";
import {uniqueId} from "app/utils/unique-id";
import {getBrowserInfo} from "app/utils/browser-report";
import ApiService from "api/services/ApiService";
import Endpoints from "api/constants/Endpoints";
import LogVisitRequest from "home/api/LogVisitRequest";
import LogLeadRequest from "remind-me/api/LogLeadRequest";
import LogProspectRequest from "remind-me/api/LogProspectRequest";

export default class AppStore
{
    isInitialized: boolean;

    emailAddress: string;

    sessionId: string = uniqueId();

    sessionInfo: any;

    constructor()
    {
        makeAutoObservable(this);
    }

    async initialize(): Promise<any>
    {
        try
        {
            this.sessionInfo = await getBrowserInfo();
        }
        catch (e)
        {
        }
        finally {
            this.isInitialized = true;
        }
    }

    async setupReminder(): Promise<boolean>
    {
        if(isNullOrEmpty(this.emailAddress) || !this.emailAddress.includes('@'))
        {
            message.error('Please enter a valid email address');
            return;
        }

        await this.logProspect();

        return true;
    }

    async logVisit()
    {
        try
        {
            let request = new LogVisitRequest({
                sessionId: this.sessionId,
                info: this.sessionInfo
            });

            await ApiService.post(Endpoints.logVisit, request);
        }
        catch (e)
        {
        }
    }

    async logLead()
    {
        try
        {
            let request = new LogLeadRequest({
                sessionId: this.sessionId,
                info: this.sessionInfo
            });

            await ApiService.post(Endpoints.logLead, request);
        }
        catch (e)
        {
        }
    }

    async logProspect()
    {
        try
        {
            let request = new LogProspectRequest({
                sessionId: this.sessionId,
                info: this.sessionInfo,
                email: this.emailAddress
            });

            await ApiService.post(Endpoints.logProspect, request);
        }
        catch (e)
        {
        }
    }
}
