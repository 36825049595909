export default class LogVisitRequest
{
    sessionId: string;
    info: any;

    public constructor(init?:Partial<LogVisitRequest>)
    {
        Object.assign(this, init);
    }
}
