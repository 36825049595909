export default class LogProspectRequest
{
    sessionId: string;
    info: any;
    email: string;

    public constructor(init?:Partial<LogProspectRequest>)
    {
        Object.assign(this, init);
    }
}
