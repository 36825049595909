import React from 'react';
import {observer} from "mobx-react";
import AppStore from "app/stores/AppStore";

interface SelectPackageProps {
    appStore?: AppStore
}

const SelectPackage: React.FC<SelectPackageProps> = observer(() =>
{
    return (
        <div className="select-package">
            Select Package
        </div>
    )
});

export default SelectPackage;

