import React from 'react';
import {observer} from "mobx-react";
import Page from "app/components/page/Page";
import "./ThankYou.scss";

interface ThankYouProps {
}

const ThankYou: React.FC<ThankYouProps> = observer(() =>
{
    return (
        <Page className="thank-you">
            <div className='message'>
                <div className='msg-title'>
                    Thank you for your interest!
                </div>
                <div className='msg-content'>
                    We will send you a reminder as soon as we're ready.
                </div>
            </div>
        </Page>
    )
});

export default ThankYou;
