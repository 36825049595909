export default class LogLeadRequest
{
    sessionId: string;
    info: any;

    public constructor(init?:Partial<LogLeadRequest>)
    {
        Object.assign(this, init);
    }
}
