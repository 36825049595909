import React from 'react';
import "./Page.scss";

interface PageProps {
    className: string
}

const Page: React.FC<PageProps> = ({children, className}) =>
{
    return (
        <div className={`page ${className}`}>
            <div className='page-header'>
                <span className='logo'>
                    <img height="40" src='/lancerio-logo.png' alt='Lancerio' title='Lancerio' />
                </span>
            </div>
            <div className='container'>
                <div className='title'>
                    <span className='get-leads'>Get freelance writing job leads on</span>
                    <span className='autopilot'>autopilot.</span>
                </div>
                {children}
            </div>
            <div className='page-footer'>
                Copyright © 2021 Lancerio. All Rights Reserved.
            </div>
        </div>
    )
};

export default Page;
