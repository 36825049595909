import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import Page from "app/components/page/Page";
import "./Home.scss";
import Step from "home/components/Step";
import {Link} from "react-router-dom";
import AppRoutes from "app/constants/AppRoutes";
import {appStore} from "index";

interface HomeProps {
}

const Home: React.FC<HomeProps> = observer(({}) =>
{
    useEffect(() =>
    {
        appStore.logVisit();
    }, []);

    return (
        <Page className='home'>
            <div className='steps'>
                <Step
                    number={1}
                    title='Choose from dozens of premium sources'
                    description='Select the sources from which you would like to receive notifications from.' />
                <Step
                    number={2}
                    title='Refine search criteria'
                    description='Optinally filter the jobs using your specific keywords or search criteria.' />
                <Step
                    number={3}
                    title='Lancerio does the rest. Relax.'
                    description='Receive notifications on your desktop or mobile devices for new gigs matching your criteria.' />
            </div>
            <div className='actions'>
                <img className='arrow' src="/arrow.png" alt="" />
                <Link className='btn-signup' to={AppRoutes.remindMe} title="Plans and Pricing">
                    Plans and Pricing
                </Link>
            </div>
            <div className='companies-title'>
                Receive freelance writing job leads from dozens of premium sources.
            </div>
            <div className='companies-list'>
                <img src="/companies/linkedin.png" alt="Linkedin" className='linkedin' />
                <img src="/companies/upwork.png" alt="Upwork" className='upwork' />
                <img src="/companies/problogger.png" alt="ProBlogger" className='problogger' />
                <img src="/companies/flexjobs.png" alt="FlexJobs" className='flexjobs' />
                <img src="/companies/workingincontent.png" alt="Working In Content" className='wic' />
            </div>
        </Page>
    )
});

export default Home;
