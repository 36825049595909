import ApiResponse from "api/models/ApiResponse";
import StatusCodes from "api/constants/StatusCodes";

export default class ApiService
{
    public static async get(endpoint: string) : Promise<ApiResponse>
    {
        let response = await this.getPrivate(endpoint);

        if(!response)
        {
            return this.apiCallFailedResponse();
        }

        if(response.ok)
        {
            return await response.json() as ApiResponse;
        }

        if(this.isForbidden(response))
        {
            return this.apiCallFailedResponse(StatusCodes.forbidden);
        }

        return this.apiCallFailedResponse(response.status);
    }

    public static async post(endpoint: string, body: any, authorized: boolean = true) : Promise<ApiResponse>
    {
        let response = await this.postPrivate(endpoint, body);

        if(!response)
        {
            return this.apiCallFailedResponse();
        }

        if(response.ok)
        {
            return await response.json() as ApiResponse;
        }

        return this.apiCallFailedResponse(response.status);
    }

    public static url(endpoint: string): string
    {
        return process.env.REACT_APP_API_SERVER_ADDRESS + endpoint;
    }

    private static jsonHeaders()
    {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
    }

    private static isForbidden(response: Response): boolean
    {
        return response && response instanceof Response && response.status === 403;
    }

    private static apiCallFailedResponse(statusCode = null) : ApiResponse
    {
        if(statusCode == null)
        {
            statusCode = StatusCodes.failed;
        }

        let message = 'An error has occurred while processing the request.';

        return ApiResponse.error(message, statusCode);
    }

    private static async postPrivate(endpoint: string, body: any) : Promise<Response>
    {
        try
        {
            return await fetch(this.url(endpoint), {
                method:  'POST',
                headers: this.jsonHeaders(),
                body: JSON.stringify(body)
            });
        }
        catch (ex)
        {
            return null;
        }
    }

    private static async getPrivate(endpoint: string) : Promise<Response>
    {
        try
        {
            return await fetch(this.url(endpoint), {
                method:  'GET',
            });
        }
        catch (ex)
        {
            return null;
        }
    }
}
