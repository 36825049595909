import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "app/components/App";
import {Provider} from "mobx-react";
import AppStore from "app/stores/AppStore";
import {configure} from "mobx";

export const appStore = new AppStore();

configure({
    enforceActions: "never",
    computedRequiresReaction: false,
    reactionRequiresObservable: false,
    observableRequiresReaction: false,
    disableErrorBoundaries: false
})

const root = ReactDOM.createRoot(document.getElementById("app"));

root.render(
    <Provider appStore={appStore}>
        <App />
    </Provider>,
);
