import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import Page from "app/components/page/Page";
import {Input} from "antd";
import "./RemindMe.scss";
import {appStore} from "index";
import {useNavigate} from "react-router";
import AppRoutes from "app/constants/AppRoutes";

interface RemindMeProps {
}

const RemindMe: React.FC<RemindMeProps> = observer(() =>
{
    const navigate = useNavigate();

    useEffect(() =>
    {
        appStore.logLead();
    }, []);

    async function remindMe(): Promise<any>
    {
        if(await appStore.setupReminder())
        {
            navigate(AppRoutes.thankYou);
        }
    }

    return (
        <Page className="remind-me">
            <div className='message'>
                <div className='msg-title'>
                    Hello! You caught us before we're ready.
                </div>
                <div className='msg-content'>
                    We're working hard to put the finishing touches onto Lancerio. Things are going well and it should be ready to help you very soon. If you'd like us to send you a reminder when we're ready, just put your email below:
                </div>
                <div className='email-form'>
                    <Input placeholder='Your Email Address ...' className='email' onChange={e => appStore.emailAddress = e.target.value} value={appStore.emailAddress} />
                    <button className='btn-remind-me' type='button' onClick={remindMe}>
                        Remind Me
                    </button>
                </div>
            </div>
        </Page>
    )
});

export default RemindMe;
