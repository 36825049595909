import React, {FC} from 'react';

interface StepProps {
    title: string,
    number: number,
    description: string
}

const Step: FC<StepProps> = ({title, number, description}) =>
{
    return (
        <div className='step'>
            <span className="step-no">
                {number}
            </span>
            <span className="step-content">
                <span className="step-title">
                    {title}
                </span>
                <span className="step-desc">
                    {description}
                </span>
            </span>
        </div>
    )
};

export default Step;
