import React from 'react';
import {observer} from "mobx-react";
import Page from "app/components/page/Page";

interface SignUpProps {
}

const SignUp: React.FC<SignUpProps> = observer(() =>
{
    return (
        <Page className="sign-up">

        </Page>
    )
});

export default SignUp;
